<template>
  <div>
    <b-modal
      ref="invite"
      id="invite"
      title="Enviar una invitacion a este culto"
      @hidden="setInvitationValues"
    >
      <div class="modal-body">
        <div class="form-group">
          <label for="exampleFormControlSelect2">Nombre</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nombre de la visita"
            v-model="nombre"
            @focus="hideMessageSent"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlSelect2">E-Mail</label>
          <input
            type="email"
            class="form-control"
            placeholder="Email de la visita"
            v-model="email"
            @focus="hideMessageSent"
          />
        </div>
        <div class="form-group">
          <b-form-checkbox v-model="canChat">
            Puede ver/interactuar en el Chat
          </b-form-checkbox>
        </div>
        <b-spinner v-if="loading" label="Loading..."></b-spinner>
        <b-alert variant="primary" :show="messageSent">{{ message }}!</b-alert>
      </div>

      <template #modal-footer="{ cancel }">
        <b-form-checkbox
          v-model="keepOpen"
          value="accepted"
          unchecked-value="not_accepted"
        >
          Enviar otra
        </b-form-checkbox>
        <b-button size="md" variant="primary" @click="sendInvitation()"
          >Enviar Invitación</b-button
        >
        <b-button size="md" variant="secondary" @click="cancel()">
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import guests from "@/api/guests";

export default {
  data: function () {
    return {
      id: "",
      nombre: "",
      email: "",
      canChat: true,
      message: "",
      loading: false,
      messageSent: false,
      keepOpen: "not_accepted",
    };
  },
  methods: {
    hideMessageSent: function () {
      this.messageSent = false;
    },
    setInvitationValues: function () {
      this.keepOpen = "not_accepted";
      this.messageSent = false;
    },
    sendInvitation: async function () {
      if (this.nombre.length === 0) {
        this.message = "Ingrese nombre";
        return;
      }

      if (this.email.length === 0) {
        this.message = "Ingrese mail";
        return;
      }

      this.loading = true;

      try {
        let response = await guests.sendInvitation({
          email: this.email,
          nombre: this.nombre,
          can_chat: this.canChat ? 1 : 0,
          site_id: 9,
        });

        if(await response == "OK"){
          this.message = `Invitación enviada a ${this.email}`;
          if (this.keepOpen === "not_accepted") {
            setTimeout(() => {
              this.$refs["invite"].hide();
            }, 1000);
          }
        } else {
          console.error("ERROR: " + response);
          this.message = "Error al enviar invitación, por favor contacta al administrador";
        }
          this.messageSent = true;
          this.loading = false;
          this.email = "";
          this.nombre = "";
      } catch (error) {
        this.message = "Ha ocurrido un error, contacte a soporte";
      }
    },
  },
};
</script>
