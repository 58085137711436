<template>
  <div class="miib-login-background">
    <div class="container amib-login" data-aos="fade-up" data-aos-delay="500">
      <div class="row vertical-offset-200">
        <div class="amib-panel-container">
          <div class="panel">
            <div class="panel-heading">
              <div class="clearfix"></div>
            </div>

            <div class="panel-body">
              <form
                accept-charset="UTF-8"
                role="form"
                action="/login"
                method="POST"
                id="login_form"
              >
                <div class="img-container">
                  <img src="../../public/img/logo.svg" />
                </div>
                <fieldset>
                  <div class="form-group">
                    <input
                      class="form-control"
                      placeholder="Correo o usuario"
                      name="user_login"
                      v-model="user_login"
                      type="text"
                      @keyup.enter="login"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control"
                      placeholder="Contraseña"
                      name="user_pass"
                      v-model="user_pass"
                      type="password"
                      value=""
                      @keyup.enter="login"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      class="btn btn-sm col-md"
                      id="miit-login-button"
                      type="button"
                      v-bind:value="btn_action"
                      v-on:click="login"
                    />
                  </div>
                  <div id="miit-login-error" class="hidden">
                    <p class="hidden danger">
                      {{ mensaje }}
                    </p>
                  </div>

                  <div class="form-group">
                    <div class="formLink text-center">
                      <router-link to="/forgotPassword">
                        Olvidé mi contraseña</router-link
                      >
                    </div>
                  </div>

                  <div class="miib-copyright">
                    <p class="text-center">
                      &copy; Iglesia Ministerio Levantando a Cristo
                    </p>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import authService from "@/services/auth";

export default {
  name: "login",
  data: function () {
    return {
      user_login: "",
      user_pass: "",
      mensaje: "",
      btn_action: "Ingresar",
    };
  },
  created: function () {
    this.checkLoggedIn();
  },
  methods: {
    isMobileDevice: function () {
      return (
        typeof window.orientation !== "undefined" ||
        navigator.userAgent.indexOf("IEMobile") !== -1
      );
    },

    checkLoggedIn: function () {
      if (window.localStorage.token) {
        this.$router.push("/");
      }
    },

    login: function () {
      let link = window.localStorage.site + "/api/login";
      let user = [];
      let token = "";
      const user_login = this.user_login.trim().toLowerCase();

      this.btn_action = "Cargando...";

      axios
        .post(link, {
          user_login: user_login,
          user_pass: this.user_pass,
        })
        .then((response) => {
          authService.clearLocalStorage();
          token = response.data.success.token;
          user = response.data.success.usuario;

          window.localStorage.token = token;
          window.localStorage.user = JSON.stringify(user);
          window.localStorage.capabilities = user.wp_9_user_level;

          this.$root.user = user;
          this.$root.capabilities = user.wp_9_user_level;
          this.setAuthorization(window.localStorage.token);

          if (this.$root.user) {
            this.$router.push("/");
          }

          this.$vToastify.success(
            "Te damos la bienvenida a Buenas Nuevas TV.",
            `Hola ${this.$root.user.display_name}!👋 `
          );
          const event = new Event("logedIn");
          dispatchEvent(event);
        })
        .catch((error) => {
          const status = error.response.status ?? null;

          if (status === 401) {
            this.mensaje = "Usuario o contraseña incorrectos";
          } else {
            this.mensaje = `Ha ocurrido un error, comuníquese con un administrador (${status}).`;
          }

          this.btn_action = "Ingresar";
        });
    },
    setAuthorization: function (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    },
  },
};
</script>

<style>
.miib-login-background {
  background-repeat: repeat;
  height: 100%;
}

.vertical-offset-200 {
  padding-top: 100px;
}

.amib-panel-container {
  width: 250px;
  margin: 0 auto;
}

.amib-login .panel {
  background: transparent;
  color: white;
}

.amib-login input[type="text"],
.amib-login input[type="email"],
.amib-login input[type="password"] {
  background-color: #3b3b3b !important;
  color: white;
  box-shadow: none;
  border: none;
  border-radius: 5px;
  text-align: left;
  padding: 20px;
}

.amib-login input[type="text"]::placeholder,
.amib-login input[type="password"]::placeholder {
  background-color: #3b3b3b;
  color: rgb(143, 143, 143);
  text-align: left;
}

.amib-login input[type="checkbox"] {
  background: transparent;
  border: 1px solid rgb(71, 71, 71);
}

.amib-login input.btn {
  background: #2d92cb;
  color: white;
  font-weight: bold;
  font-size: 15px;
  border-radius: 5px;
  width: 100%;
  padding: 10px 20px;
}

.amib-login input.btn:hover {
  background: rgb(255, 255, 255);
  color: #2d92cb;
}

.formLink a {
  color: #2d92cb;
}

.miib-copyright {
  color: white;
  font-size: 13px;
  font-weight: 100;
  margin-top: 30px;
}

.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin-bottom: 30px;
}

.img-container img {
  width: 80px;
}

.danger {
  color: #ff4f4f;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
}

h2.vt-title {
  font-size: 16px !important;
}

@media (max-width: 370px) {
  .vertical-offset-200 {
    padding-top: 10px;
  }
}
</style>
